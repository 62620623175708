<template>
    <div>
        <Echart :options="options" id="centreRight2Chart1" height="174px" width="174px"></Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        cdata: {
            handler(newData) {

                this.options = {
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    // legend: {
                    //     top: '5%',
                    //     left: 'center'
                    // },
                    color:['rgba(21, 154, 255, 1)','rgba(102, 225, 223, 1)','rgba(208, 222, 238, 1)','rgba(21, 99, 255, 1)','rgba(52, 255, 191, 1)'],
                    series: [
                        {
                            name: '农产品销售占比',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                    fontSize: 20,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: newData.data
                        }
                    ]
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>