<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        indicatorData:['4月', '5月', '6月', '7月', '8月', '9月'],
        seriesData1: [19,23,18,29,19,30],
        seriesData2: [17,12,18,15,35,19],
      }
    }
  },
  components: {
    Chart,
  }
}
</script>