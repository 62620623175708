<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        dimensions: ['月份', '触达率'],
        xData: ["4月", "5月", "6月", "7月", "8月", "9月"],
        data1:[400, 390, 290, 380, 440,380],
        data2:[9, 29, 18, 29, 13, 20,]
      },
      btnIndex: ''
    }
  },
  components: {
    Chart,
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bigbox {
  margin-top: 10px;
}
</style>