<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
    <div class="lable-item">
      <div class="item" v-for="(item, index) in lableData" :key="index">
        <div class="left">
          <div class="item-color" :style="{ 'background-color': item.color }"></div>
          <div class="item-text">{{ item.text }}</div>
        </div>
        <div class="right">
          <div class="item-proportion">{{ item.proportion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        data: [
          { value: 480, name: 'Search Engine' },
          { value: 220, name: 'Direct' },
          { value: 130, name: 'Email' },
          { value: 100, name: 'Union Ads' },
          { value: 70, name: 'Video Ads' }
        ]
      },
      lableData: [
        {
          color: 'rgba(21, 154, 255, 1)',
          text: '西红柿',
          proportion: '48%'
        },
        {
          color: 'rgba(102, 225, 223, 1)',
          text: '黄瓜',
          proportion: '22%'
        },
        {
          color: 'rgba(208, 222, 238, 1)',
          text: '白菜',
          proportion: '13%'
        },
        {
          color: 'rgba(21, 99, 255, 1)',
          text: '玉米',
          proportion: '10%'
        },
        {
          color: 'rgba(52, 255, 191, 1)',
          text: '小麦',
          proportion: '7%'
        },
      ]
    }
  },
  components: {
    Chart,
  }
}
</script>
<style lang="scss" scoped>
.bigbox {
  display: flex;
  align-items: center;

  .item {
    width: 230px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 21px 0 12px;
    background: rgba(108, 128, 151, 0.1);
    position: relative;

    .left {
      display: flex;
      align-items: center;
    }

    .item-color {
      width: 8px;
      height: 8px;
      background: rgba(51, 204, 204, 1);
    }

    .item-text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.86px;
      margin: 0 17px 0 10px;
    }

    .item-num {
      margin-right: 18px;
    }

    .item-proportion {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      color: rgba(208, 222, 238, 1);
      position: absolute;
      top: 50%;
      right: 21px;
      transform: translate(0,-50%);
    }
  }
}
</style>  